import { SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SALES_SUPERVISOR, SELLER } from '../const'

export default ({ businessId }) => [
  {
    title: 'Tienda',
    icon: 'mdi-store',
    group: 'admin',
    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SALES_SUPERVISOR, SELLER],
    children: [
      {
        icon: 'mdi-cloud-upload-outline',
        title: 'Carga masiva de transacciones para tienda',
        to: 'transactions/store/bulk-load',
        access: [SUPER_ADMIN, ADMIN],
      },
      // {
      //   icon: 'mdi-cart-arrow-down',
      //   title: 'Solicitar Despachos',
      //   to: 'business/concession-request',
      //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER],
      // },
      // {
      //   icon: 'mdi-stamper',
      //   title: 'Aprobar ventas',
      //   to: 'business/sales-no-approvate',
      //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER],
      // },
      {
        icon: 'mdi-currency-usd',
        title: 'Presupuestos',
        // to: 'business/sales',
        to: 'business/new-sale',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SALES_SUPERVISOR, SELLER],
      },
      {
        icon: 'mdi-archive-arrow-down-outline',
        title: 'Devoluciones',
        to: 'business/returns',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SALES_SUPERVISOR],
      },
      {
        icon: 'mdi-newspaper-variant',
        title: 'Reportes',
        to: 'warehouse/reports',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SALES_SUPERVISOR],
      },
      // {
      //   icon: 'mdi-archive-arrow-down-outline',
      //   title: 'Anulación de presupuesto',
      //   to: 'currencies',
      //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SALES_SUPERVISOR],
      // },
    ],
  },
]
