// import dashboardActionsLinks from '../data/dashboardActionsLinks.js'
// import dashboardPreActionsLinks from '../data/dashboardPreActionsLinks.js'
import adminDashboardLinks from '../data/adminDashboardLinks'
import storeDashboardLinks from '../data/storeDashboardLinks'
import warehouseDashboardLinks from '../data/warehouseDashboardLinks'
import { mapState } from 'vuex'
import { SUPER_ADMIN, GENERAL_MANAGER, ADMIN } from '../const'

const linksByRol = (links, user, businessId) => {
  let auth = user.authorization?.filter(auth => auth.businessId === businessId)

  if (auth.length >= 1) {
    auth = auth[0]
  }
  return links.filter(
    (link) => {
      if (link.children) {
        link.children = linksByRol(link.children, user, businessId)
      }
      return link.access.find((access) => access === auth.roleId)
    },
  )
}
export default {
  computed: {
    ...mapState(['user', 'actionsBusiness', 'actionEnvironment', 'access']),
    links () {
      // const environment = 'warehouse'
      let links = []

      if (this.actionsBusiness && this.actionEnvironment) {
        let pseudoLinks = this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0 ||
            this.user?.authorization.filter(auth => auth.roleId === GENERAL_MANAGER).length > 0 ||
            this.user?.authorization.filter(auth => auth.roleId === ADMIN).length > 0
          ? adminDashboardLinks({ businessId: this.actionsBusiness })
          : linksByRol(adminDashboardLinks({ businessId: this.actionsBusiness }), this.user, this.actionsBusiness)

        if (this.actionEnvironment === 'business') {
          const roleLink = this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0 ||
              this.user?.authorization.filter(auth => auth.roleId === GENERAL_MANAGER).length > 0 ||
              this.user?.authorization.filter(auth => auth.roleId === ADMIN).length > 0
            ? storeDashboardLinks({ businessId: this.actionsBusiness })
            : linksByRol(storeDashboardLinks({ businessId: this.actionsBusiness }), this.user, this.actionsBusiness)

          pseudoLinks = pseudoLinks.concat(roleLink)
        } else if (this.actionEnvironment === 'warehouse') {
          const roleLink = this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0 ||
              this.user?.authorization.filter(auth => auth.roleId === GENERAL_MANAGER).length > 0 ||
              this.user?.authorization.filter(auth => auth.roleId === ADMIN).length > 0
            ? warehouseDashboardLinks({ businessId: this.actionsBusiness })
            : linksByRol(warehouseDashboardLinks({ businessId: this.actionsBusiness }), this.user, this.actionsBusiness)
          pseudoLinks = pseudoLinks.concat(roleLink)
        }

        links = pseudoLinks
        pseudoLinks = links

        // links
      }

      return this.access
      // return links

      // return this.actionsBusiness
      //   ? adminDashboardLinks('1')
      //     .concat(
      //       environment === 'business'
      //         ? storeDashboardLinks('1')
      //         : environment === 'warehouse'
      //           ? warehouseDashboardLinks('1')
      //           : [])
      //   : []

      // return this.actionsBusiness !== false
      //   ? this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0 ||
      //     this.user?.authorization.filter(auth => auth.roleId === GENERAL_MANAGER).length
      //   ? dashboardActionsLinks({ businessId: this.actionsBusiness })
      //   : linksByRol(dashboardActionsLinks({ businessId: this.actionsBusiness }), this.user, this.actionsBusiness)
      //   : dashboardPreActionsLinks()
    },
  },
}
