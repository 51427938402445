// import { SUPER_ADMIN, ADMIN, GENERAL_MANAGER } from '../const'
import {
  SUPER_ADMIN,
  ADMIN,
  GENERAL_MANAGER,
  // STORE_MANAGER,
  // WAREHOUSE_MANAGER,
  // SALES_SUPERVISOR,
  // SELLER,
} from '../const'

export default ({ businessId }) => [
  // {
  //   icon: 'mdi-view-dashboard',
  //   title: 'Dashboard',
  //   to: `/business/${businessId}`,
  //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, WAREHOUSE_MANAGER, SALES_SUPERVISOR, SELLER],
  // },
  // {
  //   icon: 'mdi-auto-upload',
  //   title: 'Carga masiva',
  //   to: '/bulk_upload',
  //   access: [
  //     SUPER_ADMIN,
  //     ADMIN,
  //     GENERAL_MANAGER,
  //     STORE_MANAGER,
  //     WAREHOUSE_MANAGER,
  //     SALES_SUPERVISOR,
  //     SELLER,
  //   ],
  // },
  {
    title: 'Administración',
    icon: 'mdi-tune',
    group: 'admin',
    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
    children: [
      {
        icon: 'mdi-information-variant',
        title: 'Informacion de Empresa',
        to: `info-business/${businessId}`,
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-auto-upload',
        title: 'Carga masiva de productos',
        to: 'products/bulk-load',
        access: [SUPER_ADMIN],
      },
      {
        icon: 'mdi-auto-upload',
        title: 'Carga masiva de transacciones',
        to: 'transactions/bulk-load',
        access: [SUPER_ADMIN],
      },
      {
        icon: 'mdi-account-cog-outline',
        title: 'Usuarios',
        to: 'users',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-clipboard-account-outline',
        title: 'Clientes',
        to: 'clients',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-account-tie-outline',
        title: 'Proveedores',
        to: 'providers',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-cash',
        title: 'Monedas',
        to: 'currencies',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-store',
        title: 'Tiendas',
        to: 'store',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-view-comfy',
        title: 'Tipos de almacen',
        to: 'warehouses_types',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-warehouse',
        title: 'Almacenes',
        to: 'warehouses',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-chart-timeline-variant',
        title: 'Linea de productos',
        to: 'product-line',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-graph',
        title: 'Clasificacion de productos',
        to: 'product-classification',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-file-tree-outline',
        title: 'Categorias de productos',
        to: 'product-category',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-sitemap',
        title: 'Tipos de productos',
        to: 'product-type',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-basket',
        title: 'Productos',
        to: 'products',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      {
        icon: 'mdi-filter-menu-outline',
        title: 'Reporte avanzado',
        to: 'reports',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      },
      // {
      //   icon: 'mdi-cash-check',
      //   title: 'Reporte por moneda',
      //   to: 'reports-currency',
      //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
      // },
    ],
  },
]
