import { SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER } from '../const'
export default ({ businessId }) => [
  {
    title: 'Almacen',
    icon: 'mdi-warehouse',
    group: 'admin',
    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
    children: [
      {
        icon: 'mdi-arrow-down-bold',
        title: 'Entradas',
        to: 'warehouse/inputs',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
      },
      {
      icon: 'mdi-arrow-up-bold',
        title: 'Salidas',
        to: 'warehouse/outputs',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
      },
      // {
      //   icon: 'mdi-arrow-left-right-bold',
      //   title: 'Movimientos',
      //   to: 'warehouse/movements',
      //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
      // },
      // {
      //   icon: 'mdi-certificate-outline',
      //   title: 'Despachos',
      //   to: 'warehouse/concessions',
      //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
      // },
      {
        icon: 'mdi-archive-arrow-down-outline',
        title: 'Devolución Despachos',
        to: 'warehouse/returns',
        access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
      },
    ],
  },
]
