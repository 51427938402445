var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',_vm._b({attrs:{"id":"core-navigation-drawer","expand-on-hover":_vm.expandOnHover,"right":_vm.$vuetify.rtl,"mobile-breakpoint":"960","dark":_vm.barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',"color":"primary","app":"","width":"260"},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({attrs:{"gradient":("to bottom, " + _vm.barColor)}},'v-img',props,false))]}},{key:"append",fn:function(){return [_c('base-item',{attrs:{"item":{
        title: 'Cerrar sesión',
        icon: 'mdi-exit-to-app',
        to: '/close-session',
      }}})]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('v-divider',{staticClass:"mb-1"}),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"color":"transparent"}},[_c('v-img',{attrs:{"src":"/Logo.png","height":"auto"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4",domProps:{"textContent":_vm._s(_vm.profile.title)}})],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-list',{attrs:{"expand":"","nav":""}},[_c('div'),_c('base-item',{attrs:{"item":{
        title: 'Principal',
        icon: 'mdi-home',
        to: '/dashboard',
      }}}),_c('base-item',{attrs:{"item":{
        title: 'Perfil de usuario',
        icon: 'mdi-account',
        to: '/profile',
      }}}),_vm._l((_vm.computedItems),function(item,i){return [(item.children)?_c('base-item-group',{key:("group-" + i),attrs:{"item":item}}):_c('base-item',{key:("item-" + i),attrs:{"item":item}})]}),_c('div')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }